<template>
  <v-dialog persistent no-click-animation width="660" v-model="open">
    <v-card>
      <v-card-title>Оценка критериев качества медицинской помощи</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="username"
          label="Логин"
          @input="onInput"
          @keyup.enter="onLogin"
        ></v-text-field>
        <v-text-field
          v-model="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          label="Пароль"
          @input="onInput"
          @keyup.enter="onLogin"
        ></v-text-field>
        <div class="d-flex align-center" style="min-height: 48px">
          <v-btn @click="onLogin" color="primary" class="mr-4">Войти</v-btn>
          <v-alert v-if="showAlert" dense outlined type="error" class="my-0">
            {{ alertMessage }}
          </v-alert>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { login } from '@/lib/requestsAPI'

export default {
  data() {
    return {
      open: true,
      showPassword: false,
      username: '',
      password: '',
      showAlert: false,
      alertMessage: '',
    }
  },
  methods: {
    ...mapActions(['setToken']),
    onInput() {
      this.showAlert = false
    },
    validateForm() {
      if (!this.username) {
        this.alertMessage = 'Введите логин'
        this.showAlert = true
        return false
      }
      if (!this.password) {
        this.alertMessage = 'Введите пароль'
        this.showAlert = true
        return false
      }
      return true
    },
    handleLoginError(err) {
      this.alertMessage = 'Неправильный логин или пароль'
      this.showAlert = true
      console.error(err)
    },
    handleLoginSuccess(res) {
      const { access_token } = res
      localStorage.setItem('token', access_token)
      this.$router.push('/')
    },
    async onLogin() {
      if (!this.validateForm()) return
      try {
        const { username, password } = this
        const res = await login({
          username,
          password,
        })
        this.handleLoginSuccess(res)
      } catch (err) {
        this.handleLoginError(err)
      }
    },
  },
}
</script>
